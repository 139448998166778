<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dates"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateRangeText"
        :class="customClass"
        background-color="white"
        dense
        flat
        hide-details
        readonly
        single-line
        :placeholder="customPlaceholder"
        v-bind="attrs"
        v-on="on"
        outlined
      >
        <template slot="append">
          <v-icon>mdi-calendar</v-icon>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <div class="d-flex">
        <v-list flat>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
            class="whitespace-nowrap"
          >
            <v-list-item
              v-for="item in shortCuts"
              :key="item.value"
              class="text-body-1"
              @click="item.handler"
              >{{ item.label }}</v-list-item
            >
          </v-list-item-group>
        </v-list>

        <v-date-picker
          v-model="dates"
          color="primary"
          :max="disableFuture ? new Date().toISOString().substr(0, 10) : null"
          :min="disablePast ? new Date().toISOString().substr(0, 10) : null"
          no-title
          range
          scrollable
          @change="updateCustomizeTime()"
        >
        </v-date-picker>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { getDateRange } from "@/core/utils/date-time";
import moment from "moment";

export default {
  props: {
    column: {
      type: Boolean,
      default: true
    },
    customClass: {
      type: [String, Object]
    },
    customPlaceholder: {
      default: ""
    },
    disableFuture: {
      type: Boolean,
      default: false
    },
    disablePast: {
      type: Boolean,
      default: false
    },
    row: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 1
    },
    value: {
      type: [String, Array],
      default: null,
      required: true
    }
  },
  data() {
    return {
      dates: [],
      selectedItem: null,
      menu: false,
      shortCuts: [
        {
          label: "Tuần trước",
          value: "lastWeek",
          handler: () => {
            const { startDate, endDate } = getDateRange("lastWeek");
            this.dates = [
              moment(startDate).format("yyyy-MM-DD"),
              moment(endDate).format("yyyy-MM-DD")
            ];

            this.$refs.menu.save(this.dates);
            this.$emit("input", this.dates);
          }
        },
        {
          label: "Tuần này",
          value: "currentWeek",
          handler: () => {
            const { startDate, endDate } = getDateRange("currentWeek");
            this.dates = [
              moment(startDate).format("yyyy-MM-DD"),
              moment(endDate).format("yyyy-MM-DD")
            ];

            this.$refs.menu.save(this.dates);
            this.$emit("input", this.dates);
          }
        },
        {
          label: "Tháng trước",
          value: "lastMonth",
          handler: () => {
            const { startDate, endDate } = getDateRange("lastMonth");
            this.dates = [
              moment(startDate).format("yyyy-MM-DD"),
              moment(endDate).format("yyyy-MM-DD")
            ];

            this.$refs.menu.save(this.dates);
            this.$emit("input", this.dates);
          }
        },
        {
          label: "Tháng này",
          value: "currentMonth",
          handler: () => {
            const { startDate, endDate } = getDateRange("currentMonth");
            this.dates = [
              moment(startDate).format("yyyy-MM-DD"),
              moment(endDate).format("yyyy-MM-DD")
            ];

            this.$refs.menu.save(this.dates);
            this.$emit("input", this.dates);
          }
        }
      ]
    };
  },
  computed: {
    dateRangeText() {
      if (this.dates.length === 0) return;

      const fromDate = new Date(this.dates[0]),
        fromDateDD = fromDate
          .getDate()
          .toString()
          .padStart(2, "0"),
        fromDateMM = (fromDate.getMonth() + 1).toString().padStart(2, "0"),
        fromDateYYYY = fromDate.getFullYear(),
        toDate = new Date(this.dates[1]),
        toDateDD = toDate
          .getDate()
          .toString()
          .padStart(2, "0"),
        toDateMM = (toDate.getMonth() + 1).toString().padStart(2, "0"),
        toDateYYYY = toDate.getFullYear();

      return `${fromDateDD}/${fromDateMM}/${fromDateYYYY} - ${toDateDD}/${toDateMM}/${toDateYYYY}`;
    }
  },

  watch: {
    value: {
      handler() {
        this.dates = this.value;
      },
      immediate: true
    }
  },

  methods: {
    updateCustomizeTime() {
      this.$refs.menu.save(this.dates);
      this.$emit("input", this.dates);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-hover {
  cursor: pointer;
}
.input-date--customize {
  font-size: 13px !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}
</style>
